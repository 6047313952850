import React, { useEffect, useMemo, useState } from 'react';
import routePaths from '../../route-paths';
import { createUseStyles } from 'react-jss';
import { TitleService } from '../../services';
import { Breadcrumbs, UserFilters } from '../../store';
import CustomerParticipationSummaryButton from '../../components/customer-participation-summary/customer-participation-summary-button';
import { Button, Theme, MetricCard, SelectBox, Search, Flyout, InformationIcon, Timing, AlertService } from '@spoiler-alert/ui-library';
import RecommendedNegotiations from '../../components/banners/recommended-negotiations';
import StaticLoading from '../static-loading';
import SuggestedNegotiationsEmptyState from './suggested-negotiations-empty-state';
import SuggestedNegotiationsCard from './suggested-negotiations-card';
import pluralize from 'pluralize';
import SuggestedNegotiationsFilter from './suggested-negotiations-filter';
import filterCounter from '../../utilities/filter-counter';
import { useMutation, useQuery } from '@apollo/client';
import { StagedNegotiationSummaryQuery, SuggestedNegotiationsQuery } from '../../graphql/queries';
import accounting from 'accounting';
import { NegotiationSettingsModal } from '../../components/modals';
import PropTypes from 'prop-types';
import { createNegotiationStagedListings } from '../../graphql/mutations';
import { negotiationOrigin } from '../../enums';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  row: {
    display: 'flex',
    '& .childRow': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  metric_row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  action_row: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '24px 0px',
  },
  page_title: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.176px',
    marginBottom: '16px',
  },
  select_box: {
    marginRight: '8px',
  },
  bulk_action: {
    marginRight: '24px',
  },
  search_bar: {
    marginRight: '10px',
  },
  settings_button: {
    marginLeft: '8px',
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  line_break: {
    margin: '16px 0px',
    borderBottom: `1px ${Theme.borderColor} solid`,
  },
  filter: {
    extend: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Theme.teal10,
    marginBottom: 24,
    '& h1': {
      fontSize: 14,
      fontWeight: 'normal',
      margin: '12px 8px',
      textWrap: 'nowrap',
    },
    '& svg': {
      maxWidth: 16,
      maxHeight: 16,
      width: 16,
      height: 16,
      margin: '13px 0px 11px 12px',
      fill: Theme.infoColor,
    },
    '& a': {
      backgroundColor: 'transparent',
      color: Theme.infoColor,
      fontSize: 14,
      marginRight: 12,
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
});

const defaultFilterState = {
  reserveAnchor: [],
};

const SuggestedNegotiations = ({ history, user }) => {
  const classes = useStyles();
  const [showFilters, setShowFilters] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({ ...defaultFilterState, ...UserFilters.filters.suggestedNegotiations });
  const [selectedNegotiations, setSelectedNegotiations] = useState([]);
  const [loadingRows, setLoadingRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const {
    data,
    loading: negotiationsLoading,
    refetch: refetchNegotiations,
  } = useQuery(SuggestedNegotiationsQuery, { fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true });
  const [negotiate, { loading: createNegotiationLoading }] = useMutation(createNegotiationStagedListings);

  const negotiationData = useMemo(() => data?.SuggestedNegotiationsQuery?.negotiations || [], [data]);

  const filteredNegotiationData = useMemo(() => {
    const searchRegex = new RegExp(searchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
    return negotiationData
      .map((d) => {
        const matchesSearch = searchText.trim() !== '' ? searchRegex.test([d.buyerSiteName]) : true;
        if (matchesSearch)
          return {
            ...d,
            offerListings: d.offerListings.filter((ol) => {
              if (ol.negotiation) return false;
              if (filters.reserveAnchor.length === 0) return true;
              return filters.reserveAnchor.includes(ol.suggestions.negotiation.reason);
            }),
          };
      })
      .filter((d) => d?.offerListings?.length > 0);
  }, [negotiationData, filters, searchText]);

  const metricCards = useMemo(() => {
    let totalSuggestions = 0;
    let totalPotentialRevenue = 0;
    let totalPotentialRevenueFromReservePrice = 0;
    let totalPotentialRevenueFromHistoricalPrice = 0;

    if (filteredNegotiationData.length > 0) {
      filteredNegotiationData.forEach((d) => {
        d.offerListings.forEach((ol) => {
          const revenueCalculation = ol?.originalQuantity * ol?.suggestions?.negotiation.unitPrice - ol?.originalTotalPrice;
          totalSuggestions++;
          totalPotentialRevenue += revenueCalculation;
          if (ol?.suggestions?.negotiation?.reason === 'RESERVE_COUNTER') {
            totalPotentialRevenueFromReservePrice += revenueCalculation;
          } else {
            totalPotentialRevenueFromHistoricalPrice += revenueCalculation;
          }
        });
      });
    }
    return [
      {
        title: 'Total Potential Revenue',
        current: accounting.formatMoney(totalPotentialRevenue) || '-',
      },
      {
        title: 'Potential Revenue from Reserve Price',
        current: accounting.formatMoney(totalPotentialRevenueFromReservePrice) || '-',
      },
      {
        title: 'Potential Revenue from Historical Price',
        current: accounting.formatMoney(totalPotentialRevenueFromHistoricalPrice) || '-',
      },
      {
        title: 'Total Suggested Negotiations',
        current: totalSuggestions.toString() || '-',
      },
    ];
  }, [filteredNegotiationData]);

  const filterCount = useMemo(() => filterCounter(filters), [filters]);

  const allSelected = useMemo(() => {
    if (filteredNegotiationData && filteredNegotiationData.length > 0) {
      let negotiationIds = [];
      filteredNegotiationData.forEach((d) => (negotiationIds = [...negotiationIds, ...d.offerListings.map((ol) => ol._id)]));
      return selectedNegotiations.length > 0 && selectedNegotiations.length === negotiationIds.length;
    }
    return false;
  }, [selectedNegotiations, filteredNegotiationData]);

  const partialSelected = useMemo(() => {
    if (filteredNegotiationData && filteredNegotiationData.length > 0) {
      let negotiationIds = [];
      filteredNegotiationData.forEach((d) => (negotiationIds = [...negotiationIds, ...d.offerListings.map((ol) => ol._id)]));
      return selectedNegotiations.length > 0 && selectedNegotiations.length < negotiationIds.length;
    }
    return false;
  }, [selectedNegotiations, filteredNegotiationData]);

  useEffect(() => {
    TitleService.setTitles('Suggested Negotiations');
    Breadcrumbs.set([
      {
        url: routePaths.suggestedNegotiations,
        title: 'Suggested Negotiations',
        badge: {
          text: 'Trial Access',
          colors: { color: Theme.tealDark, backgroundColor: Theme.teal10 },
        },
      },
    ]);
  }, []);

  const handleSearch = Timing.debounce((text) => setSearchText(text), 300);

  const goToInventory = () => {
    history.push('/inventory');
  };

  const toggleFilters = () => setShowFilters(!showFilters);

  const clearFilters = () => {
    setFilters(defaultFilterState);
    UserFilters.updateFilters('suggestedNegotiations', defaultFilterState);
  };

  const selectAll = (event) => {
    if (event.checked) {
      let negotiationIds = [];
      filteredNegotiationData.forEach((d) => (negotiationIds = [...negotiationIds, ...d.offerListings.map((ol) => ol._id)]));
      setSelectedNegotiations(negotiationIds);
    } else {
      setSelectedNegotiations([]);
    }
  };

  const reasonMessage = (reasonType, unitPrice) => {
    return reasonType === 'RESERVE_COUNTER'
      ? 'Please meet my minimum price for this item to be awarded this inventory.'
      : `You paid ${accounting.formatMoney(unitPrice)} for this inventory previously. Please meet this price to be awarded this inventory.`;
  };

  const stageNegotiations = Timing.throttle(async () => {
    setLoadingRows(selectedNegotiations);
    const counterOffers = [];
    selectedNegotiations.forEach((negotiationId) => {
      const negotiation = negotiationData.find((d) => d.offerListings.find((ol) => ol._id === negotiationId));
      const row = negotiation.offerListings.find((ol) => ol._id === negotiationId);
      counterOffers.push({
        offerListingId: row._id,
        counterOfferAnchor: row.suggestions?.negotiation.reason === 'RESERVE_COUNTER' ? 'ReservePrice' : 'BestHistoricalPrice',
        counterOfferPrice: row.suggestions?.negotiation.unitPrice,
        reason: reasonMessage(row.suggestions?.negotiation.reason, row.suggestions?.negotiation.unitPrice),
      });
    });
    const response = await negotiate({
      variables: {
        counterOffers,
        negotiationOrigin: negotiationOrigin.SUGGESTED_NEGOTIATION,
        tags: ['suggested-negotiation-staged'],
      },
      refetchQueries: [
        {
          query: StagedNegotiationSummaryQuery,
        },
      ],
    });

    if (response.data?.createNegotiationStagedListings?.errors?.length) {
      AlertService.alert({
        type: 'error',
        message: response.data?.createNegotiationStagedListings?.errors[0].message,
        autoDismiss: true,
      });
    } else {
      AlertService.alert({
        type: 'success',
        message: <span>{`Staged ${counterOffers.length} Negotiations`}</span>,
        autoDismiss: true,
      });
    }
    setLoadingRows([]);
    setSelectedNegotiations([]);
  }, 1000);

  const actionRow = (
    <div className={classes.row}>
      <SelectBox
        id={'select-all-negotiations-checkbox'}
        className={classes.select_box}
        partial={partialSelected}
        all={allSelected}
        onSelect={selectAll}
      />
      <Button className={classes.bulk_action} onClick={stageNegotiations} loading={createNegotiationLoading} loadingText={'Staging Negotiations'}>
        {selectedNegotiations.length > 0
          ? `Stage ${selectedNegotiations.length} ${pluralize('Negotiations', selectedNegotiations.length)} `
          : 'Stage Negotiations'}
      </Button>
      <Search onChange={handleSearch} className={classes.search_bar} />
      <Button onClick={toggleFilters} secondary>
        {filterCount > 0 ? `${filterCount} ${pluralize('Filter', filterCount)}` : 'Filter'}
      </Button>
    </div>
  );

  const filterRow = filterCount > 0 && (
    <div className={classes.filter}>
      <div className="childRow">
        <InformationIcon />
        <h1>Some suggestions maybe hidden from view based on your filters or search</h1>
      </div>
      <a onClick={clearFilters}>Clear All Filters</a>
    </div>
  );

  if (negotiationsLoading)
    return (
      <div className={classes.loader}>
        <StaticLoading />
      </div>
    );

  return (
    <div>
      <RecommendedNegotiations />
      {filteredNegotiationData.length === 0 && !filterCount && !searchText ? (
        <SuggestedNegotiationsEmptyState history={history} />
      ) : (
        <div>
          <h1 className={classes.page_title}>Total Opportunity</h1>
          <div className={classes.metric_row}>
            {metricCards.map((card, i) => (
              <MetricCard key={`metric-card-${i}`} title={card.title} current={card.current} />
            ))}
          </div>
          <div className={classes.action_row}>
            {actionRow}
            <div className={classes.row}>
              <CustomerParticipationSummaryButton createListingsHandler={goToInventory} />
              <Button className={classes.settings_button} onClick={() => setModalOpen(true)}>
                Negotiation Settings
              </Button>
            </div>
          </div>
          {filterRow}
          {filteredNegotiationData.length > 0 ? (
            filteredNegotiationData.map((d, index) => (
              <div key={d.buyerSiteName}>
                <SuggestedNegotiationsCard
                  negotiations={d.offerListings}
                  buyerName={d.buyerSiteName}
                  selectedRows={selectedNegotiations}
                  setSelectedRows={setSelectedNegotiations}
                  loadingRows={loadingRows}
                  setLoadingRows={setLoadingRows}
                  user={user}
                />
                {filteredNegotiationData.length - 1 !== index && <div className={classes.line_break} />}
              </div>
            ))
          ) : (
            <div className={classes.container}>No suggestions match your search or filters</div>
          )}
        </div>
      )}
      <Flyout position="right" open={showFilters} onHide={toggleFilters}>
        <SuggestedNegotiationsFilter filters={filters} setFilters={setFilters} />
      </Flyout>
      <NegotiationSettingsModal
        history={history}
        onCancel={() => setModalOpen(false)}
        modalOpen={modalOpen}
        onSave={() => {
          setModalOpen(false);
          refetchNegotiations();
        }}
      />
    </div>
  );
};

SuggestedNegotiations.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
};

export default SuggestedNegotiations;
