import React, { useEffect, useMemo, useState } from 'react';
import {
  GraphQLDataTable,
  Filter,
  Button,
  RowAction,
  ChangeQuantityIcon,
  ClearListIcon,
  AlertService,
  PublishListIcon,
  AdjustSuggestedPriceIcon,
  ButtonWithDisclosure,
  Column,
} from '@spoiler-alert/ui-library';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { inject, observer } from 'mobx-react';
import pluralize from 'pluralize';
import NegotiationTextbox from './negotiation-textbox';
import NegotiationQuantityModal from './negotiation-quantity-modal';
import NegotiationSuggestedPriceModal from './negotiation-suggested-price-modal';
import { NegotiationStagedListingsQuery, StagedNegotiationSummaryQuery, NegotiationListingsQuery } from '../../graphql/queries';
import { clearNegotiationStagedListings, publishNegotiationListings, resetNegotiationStagedListingsByIds } from '../../graphql/mutations';
import deleteCachedFieldsOnUserQuery from '../../apollo/cache-helpers/delete-cached-fields-on-user-query';
import { getColumnsFromDataTableProfile } from '../../components/data-table';
import { TitleService } from '../../services';
import routePaths from '../../route-paths';
import { Breadcrumbs } from '../../store';
import styles from './negotiations-detail-styles';
import NegotiationMessage from './negotiation-message';
import featureFlags from '../../enums/feature-flags';
import checkFeature from '../../helpers/check-feature-flag';

const useStyles = createUseStyles(styles);

const NegotiationsDetail = ({ user, match, userFiltersStore, history }) => {
  const classes = useStyles();
  const [showNegotiationModal, setShowNegotiationModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [total, setTotal] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [showQuantityChangeModal, setShowQuantityChangeModal] = useState(false);
  const [negotiationDetail, setNegotiationDetail] = useState({});
  const [resetting, setResetting] = useState(false);
  const [actionOnRows, setActionOnRows] = useState([]);
  const [tableDeletedList, setTableDeletedList] = useState([]);
  const [closeOnPromise, setCloseOnPromise] = useState(null);
  const [ClearNegotiationStagedListings] = useMutation(clearNegotiationStagedListings);
  const [PublishNegotiationListings, { loading }] = useMutation(publishNegotiationListings);
  const [ResetNegotiationStagedListingsByIds] = useMutation(resetNegotiationStagedListingsByIds);
  const negotiationContextEnabled = checkFeature(featureFlags.negotiationContext);
  const variables = {
    paginate: {
      pageNumber: 1,
      limit: 30,
      filter: {
        buyerSiteIds: [match.params.buyerSiteId],
        sortBy: { asc: false, sortProperty: 'siteName' },
      },
    },
  };

  useEffect(() => {
    TitleService.setTitles('Negotiations Detail');
    Breadcrumbs.set([
      {
        url: routePaths.negotiations,
        title: 'Negotiations',
      },
      {
        url: match.url,
        title: match.params.buyerSiteName,
      },
    ]);
  }, [match.url]);

  let inventoryFilters = null;

  const createInitialFilters = () => {
    if (inventoryFilters) return inventoryFilters;

    const locationFilter = { displayName: 'Location', field: 'sites', queryField: 'sites', selectAll: true, filterType: 'search' };
    const defaultFilters = [
      { displayName: 'Created Date Range', filterType: 'daterange' },
      { displayName: 'Code Date Range', filterType: 'daterange', options: { startParam: 'bestByStartDate', endParam: 'bestByEndDate' } },
      { displayName: 'Category', field: 'categories', selectAll: true },
      { displayName: 'Handling', field: 'handlings', selectAll: true },
      { displayName: 'Truck Type', field: 'truckTypes', selectAll: true },
      { displayName: 'Brand', field: 'brands', filterType: 'search', selectAll: true },
    ];
    if (user.privileges.canUserManageMultipleSites) defaultFilters.splice(1, 0, locationFilter);
    if (negotiationContextEnabled)
      defaultFilters.push(
        { displayName: '', field: 'negotiationMessageExists', selectAll: true, multiple: false },
        { field: 'negotiationMessageDoesNotExist', selectAll: true, multiple: false }
      );

    const storeFilters = userFiltersStore.filters.negotiationsDetail || [];
    inventoryFilters = defaultFilters.map((df) => new Filter({ ...df, ...storeFilters.find((sf) => sf?.displayName === df.displayName) }));
    return inventoryFilters;
  };

  const addToActionOnRowIds = (rowId, action) => {
    const newRows = [...actionOnRows];
    newRows.push({ rowId, action });
    return newRows;
  };

  const removeFromActionOnRowIds = (rowId) => {
    return actionOnRows.filter((o) => o.rowId !== rowId);
  };

  const showSuccess = (message, rowId) => {
    let updatedActionOnRows;
    let updatedTableDeletedList;
    setResetting(false);
    if (rowId) {
      updatedActionOnRows = removeFromActionOnRowIds(rowId);
      updatedTableDeletedList = [...tableDeletedList, rowId];
    }
    if (updatedActionOnRows) setActionOnRows(updatedActionOnRows);
    if (updatedTableDeletedList) setTableDeletedList(updatedTableDeletedList);
    AlertService.alert({
      type: 'success',
      message: <span>{message}</span>,
      autoDismiss: true,
      dismissDelay: 3000,
    });
  };

  const showFailure = (message, rowId) => {
    let updatedActionOnRows;
    if (rowId) {
      updatedActionOnRows = removeFromActionOnRowIds(rowId);
    }

    setResetting(false);
    setActionOnRows(updatedActionOnRows);
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  };

  const handleClearOne = (row, e) => {
    e.stopPropagation();
    const updatedActionOnRows = addToActionOnRowIds(row._id, 'reset');
    setActionOnRows(updatedActionOnRows);
    ResetNegotiationStagedListingsByIds({
      variables: { negotiationStagedListingIds: [row.negotiationListing._id] },
      refetchQueries: [{ query: StagedNegotiationSummaryQuery }, { query: NegotiationStagedListingsQuery, variables }],
    })
      .then((response) => {
        const result = response.data.resetNegotiationStagedListingsByIds;
        if (result.negotiationStagedListingsCount === 0 || result.errors.length > 0) throw new Error(result.errors.join(', '));
        showSuccess(
          `You have cleared ${result.negotiationStagedListingsCount} ${pluralize('negotiation', result.negotiationStagedListingsCount)}.`,
          row._id
        );
      })
      .catch(() => showFailure('A problem occurred while clearing negotiations.', row._id));
  };

  const handleChangeQuantity = (row, e) => {
    e.stopPropagation();
    setNegotiationDetail(row);
    setShowQuantityChangeModal(true);
  };

  const rowActions = () => {
    const resettingRows = [];
    const changingRows = [];
    const activeRows = [];
    if (actionOnRows?.length > 0) {
      actionOnRows.forEach((r) => {
        if (r.action === 'reset') {
          resettingRows.push(r.rowId);
        } else {
          changingRows.push(r.rowId);
        }
        activeRows.push(r.rowId);
      });
    }
    return [
      <RowAction
        key={1}
        tooltipText="Clear Negotiation"
        loadingTooltipText="Clearing Negotiation"
        icon={ClearListIcon}
        onClick={(row) => handleClearOne.bind(this, row)}
        warning
        loadingRows={resettingRows}
        disabledRows={activeRows}
      />,
      <RowAction
        key={2}
        tooltipText="Change Quantity"
        loadingTooltipText="Changing Quantity"
        icon={ChangeQuantityIcon}
        onClick={(row) => handleChangeQuantity.bind(this, row)}
        loadingRows={changingRows}
        disabledRows={activeRows}
      />,
    ];
  };

  const hideQuantityModal = () => {
    if (showQuantityChangeModal) {
      setShowQuantityChangeModal(false);
      setNegotiationDetail({
        ...negotiationDetail,
        negotiationListing: {
          suggestedQuantity: null,
          offerDetails: {
            quantity: null,
          },
        },
        onHandQuantity: null,
        unitNetWeight: null,
        unitGrossWeight: null,
      });
    }
  };

  const refetchQueries = [{ query: StagedNegotiationSummaryQuery }, { query: NegotiationStagedListingsQuery, variables }];

  const handleClearAll = (e) => {
    e.stopPropagation();
    setResetting(true);
    ClearNegotiationStagedListings({
      variables: { buyerSiteIds: [match.params.buyerSiteId] },
      update: (cache) => deleteCachedFieldsOnUserQuery(cache, ['negotiationStagedListings', 'negotiationStagedInventoryFilterParameters']),
      refetchQueries: [{ query: StagedNegotiationSummaryQuery }, { query: NegotiationStagedListingsQuery, variables }],
    })
      .then((response) => {
        const result = response.data.clearNegotiationStagedListings;
        if (result.negotiationStagedListingsCount === 0 || result.errors.length > 0) throw new Error(result.errors.join(', '));
        showSuccess(
          `You have cleared ${result.negotiationStagedListingsCount} ${pluralize('negotiation', result.negotiationStagedListingsCount)} from ${
            match.params.buyerSiteName
          }.`
        );
        history.push(routePaths.negotiations);
      })
      .catch(() => showFailure('A problem occurred while clearing negotiations.'));
  };

  const filterParameters = { buyerSiteIds: [match.params.buyerSiteId] };

  const clearTableDeletedList = () => {
    setTableDeletedList([]);
  };

  const handleSelectChange = (newSelected, newTotal, newAllSelected) => {
    setSelected(newSelected);
    setTotal(newTotal);
    setAllSelected(newAllSelected);
  };

  const openNegotiationModal = () => {
    setShowNegotiationModal(true);
  };

  const createMessage = (createdCount) => {
    const message = createdCount > 1 ? `${createdCount} changes were successfully made.` : `${createdCount} change was successfully made.`;
    AlertService.alert({ type: 'success', message: <span>{message}</span>, autoDismiss: true, dismissDelay: 3000 });
  };

  const error = (errorCount, loggedCount) => {
    const message = `Sorry ${errorCount} of ${
      loggedCount + errorCount
    } changes failed. If this problem persists, please contact a Spoiler Alert Administrator to help you.`;
    AlertService.alert({ type: 'warning', message: <span>{message}</span> });
  };

  const hideModal = (errorCount, loggedCount) => {
    if (errorCount > 0) error(errorCount, loggedCount);
    if (loggedCount > 0 && errorCount === 0) createMessage(loggedCount);
    setShowNegotiationModal(false);
  };

  const onSubmit = (event, customMessage) => {
    event.stopPropagation();
    const promise = PublishNegotiationListings({
      variables: { buyerSiteIds: [match.params.buyerSiteId], customMessage },
      refetchQueries: [{ query: StagedNegotiationSummaryQuery }, { query: NegotiationStagedListingsQuery, variables }],
    })
      .then((response) => {
        if (response.data?.publishNegotiationListings?.errors?.length > 0) throw new Error(response.errors.join(', '));
        AlertService.alert({ type: 'success', message: <span>Negotiation list successfully published.</span>, autoDismiss: true });
        history.push(routePaths.negotiations);
      })
      .catch(() => {
        AlertService.alert({
          type: 'warning',
          message: (
            <span>
              Sorry there was an error publishing your negotiation. If this problem persists, please contact a Spoiler Alert Administrator to help
              you.
            </span>
          ),
        });
      });
    setCloseOnPromise(promise);
  };

  const pageActionButtons = () => {
    return [
      <Button warning key="clear" loading={resetting} icon={ClearListIcon} onClick={handleClearAll} loadingText="Clearing List">
        Clear All
      </Button>,
      <ButtonWithDisclosure
        key="publish"
        buttonText="Publish List"
        loadingText="Publishing"
        icon={PublishListIcon}
        onSubmit={onSubmit}
        closeOnPromise={closeOnPromise}
        loading={loading}
        disabled={loading}
        placeholder="Use this space if you would like to communicate to your customer why you are negotiating."
      />,
    ];
  };

  const bulkActionButtons = () => {
    return [
      <Button key="adjust" primary icon={AdjustSuggestedPriceIcon} onClick={openNegotiationModal}>
        Adjust Counter Offer
      </Button>,
    ];
  };

  const publishedColumns = useMemo(() => {
    const publishedColumnOverrides = {
      negotiationListing_suggestedUnitPrice: {
        formatter: (_, row) => row.negotiationListing.suggestedUnitPrice,
      },
    };
    const publishedCols = [...getColumnsFromDataTableProfile('Negotiations Detail', user.site.dataTableProfiles, publishedColumnOverrides, true)];
    return publishedCols;
  }, [user.site.dataTableProfiles]);

  const columns = useMemo(() => {
    const columnOverrides = {
      negotiationListing_suggestedUnitPrice: {
        formatter: (_, row) => <NegotiationTextbox key={row._id} row={row} onFailure={showFailure} refetchQueries={refetchQueries} />,
      },
    };
    if (!negotiationContextEnabled) return [...getColumnsFromDataTableProfile('Negotiations Detail', user.site.dataTableProfiles, columnOverrides)];
    const cols = [...getColumnsFromDataTableProfile('Negotiations Detail', user.site.dataTableProfiles, columnOverrides)];
    if (cols.find((col) => col.field === 'negotiationListing.reason')) return cols;

    const messageCol = new Column({
      field: 'negotiationListing.reason',
      displayName: '',
      visible: true,
      sortable: false,
      formatter: (_, row) => <NegotiationMessage row={row} key={row._id} />,
    });

    cols.splice(cols.length, 0, messageCol);
    return cols;
  }, [negotiationContextEnabled, showFailure, user.site.dataTableProfiles]);

  return (
    <div className={classes.negotiations__wrap}>
      <div className={classes.table_header}>Unpublished</div>
      <GraphQLDataTable
        query={NegotiationStagedListingsQuery}
        queryField="negotiationStagedListings"
        filterParameterField="negotiationStagedInventoryFilterParameters"
        search
        queryParameters={filterParameters}
        columns={columns}
        filters={createInitialFilters(user)}
        updateStoredFilters={(filters) => userFiltersStore.updateFilters('negotiationsDetail', filters)}
        deletedList={tableDeletedList}
        clearDeletedList={clearTableDeletedList}
        selectable
        onSelectChange={handleSelectChange}
        perPage={30}
        rowActions={rowActions()}
        pageActionButtons={pageActionButtons()}
        bulkActionButtons={bulkActionButtons()}
        refetchData
        noDataMessage="There are no unpublished negotiations"
        removeSearchRowOnEmpty
      />
      <div className={classes.table_header}>Previously Published</div>
      <GraphQLDataTable
        query={NegotiationListingsQuery}
        queryField="negotiationListings"
        queryParameters={filterParameters}
        columns={publishedColumns}
        perPage={30}
        refetchData
        noDataMessage="There are no published negotiations"
        noSearchRow
      />
      <NegotiationQuantityModal
        open={showQuantityChangeModal}
        onHide={hideQuantityModal}
        negotiationDetail={negotiationDetail}
        refetchQueries={refetchQueries}
        user={user}
      />
      <NegotiationSuggestedPriceModal
        open={showNegotiationModal}
        onHide={hideModal}
        negotiationStagedListingIds={selected.map((nsl) => nsl.negotiationListing._id)}
        allSelected={allSelected}
        total={total}
        filters={variables.paginate.filter}
        refetchQueries={refetchQueries}
        user={user}
      />
    </div>
  );
};

NegotiationsDetail.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
  match: PropTypes.object,
  clearStagedListings: PropTypes.func,
  publishStagedListings: PropTypes.func,
  createStagedListingsFromInventory: PropTypes.func,
  userFiltersStore: PropTypes.object,
  history: PropTypes.object,
};

const ConnectedComponent = inject((store) => store)(observer(NegotiationsDetail));

export default ConnectedComponent;
